import Editor from '@tinymce/tinymce-vue'
import validation from '@/mixins/validation'

export default {
  name: 'main-editor',
  mixin: [validation],
  stubs: {
    transition: false
  },
  props: {
    value: {
      type: String,
      default: '<p></p>'
    },
    label: {
      type: String,
      default: ''
    },
    isNotWatchPropsValue: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      key: process.env.VUE_APP_TINY_EDIT,
      render: false,
      content: '',
      config: {
        selector: 'textarea',
        schema: 'html5',
        file_browser_callback: function (field_name, url, type, win) {
          // if (type == 'image') $('#img_editor_upload input').click();
        },
        contextmenu: 'link image imagetools table spellchecker',
        height: 300,
        file_browser_callback_types: 'image',
        images_upload_url: '/uploadMCE',
        automatic_uploads: false,
        fontsize_formats: '8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 39px 34px 38px 42px 48px',
        plugins: 'advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking save table directionality emoticons template paste',
        toolbar1: 'insertfile undo redo | styleselect fontsizeselect | bold italic strikethrough forecolor backcolor link | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image | print preview media fullpage'
      }
    }
  },
  components: {
    Editor
  },
  created () {
    if (this.value) {
      this.content = this.value
    }
  },
  mounted () {
    setTimeout(() => {
      this.render = true
    }, 1)
  },
  watch: {
    value () {
      if (!this.isNotWatchPropsValue) {
        this.content = this.value
      }
    }
  },
  methods: {
    handleInput (val) {
      this.$emit('input', val)
    },
    onChange () {
      this.$emit('on-change', this.content)
    }
  },
  destroyed () {
    this.render = false
  }
}
